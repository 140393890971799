import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const UnsichereZeitenAuswirkungenAufDenHauskauf = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Unsichere Zeiten: Auswirkungen auf den Hauskauf" showCalc={false} />
            <Article>
                <p>
                    Angesichts aktueller geopolitischer, weltgesundheitlicher und nicht zuletzt klimatischer Krisen,
                    angesichts des Ukrainekriegs, der noch immer andauernden Coronapandemie sowie des Klimawandels und
                    seiner wirtschaftlichen Folgen sind zahlreiche Konsument*innen verunsichert: Welche Auswirkungen hat
                    die weltweit angespannte Lage auf einen bevorstehenden Hauskauf? Ist eine{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    überhaupt noch leistbar? Diesen Fragen widmen wir uns im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>So entwickeln sich die Immobilienpreise in Österreich.</h2>
                <p>
                    Wenn du aktuelle Nachrichten zur Weltfinanz verfolgst, dann weißt du wahrscheinlich, dass auch die
                    Immobilienpreise auf dem österreichischen Markt in der jüngsten Vergangenheit drastisch gestiegen
                    sind. Schon länger geistert daher das Wort “Immobilienblase” durch die heimischen
                    Wirtschtschaftsblätter. Unter diesem Begriff versteht man den – bezogen auf die Teuerung anderer
                    Waren – übermäßig starken Anstieg von Immobilienpreisen. Erreicht der Preis ein Niveau, das die
                    Angebote abrupt zurückgehen lässt, dann platzt die Immobilienblasen . In anderen Worten – und um den
                    Bildbereich der Seifenblasen zu verlassen: die Preise fallen rasch und drastisch, was negative
                    Auswirkungen auf die gesamte Wirtschaft hat.
                </p>
                <hr />

                <h2>Gibt es eine Immobilienblase in Österreich?</h2>
                <p>
                    In Österreich mehren sich derzeit zumindest die Anzeichen einer solchen Immobilienblase. Das lässt
                    sich an der raschen Preisentwicklung nicht nur von Immobilien in urbanen Ballungsräumen ablesen,
                    sonder vor allem daran, dass auch Objekte in wenig gefragten Lagen, deren Bewertung unter anderen
                    Umständen aufgrund ihrer Bauqualität deutlich niedriger ausfallen würde, mittlerweile
                    unverhältnismäßig teuer sind.
                </p>
                <p>
                    Die Preise befinden also im Allgemeinen auf einem historischen Hoch. Dies lässt sich unter anderem
                    durch Investoren eklären, die ihr Geld in Immobliien anlegen. Es handelt sich in der Regel um sehr
                    wohlhabende Bürger:innen, die über ein Vermögen von über 30 Millionen Euro netto verfügen, das ihnen
                    für Investitionen zur freien Verfügung steht. Investieren die wenigen Vermögende nun weiterhin in
                    die ohnehin schon überteuerten Immobilien, dann treiben sie die Preise noch weiter hoch. Etwa die
                    Hälfte der Österreicher:innen besitzt hingegen weiterhin überhaupt kein Wohneigentum. Und ohne die
                    richtige Hilfe wird es für sie auch in Zukunft nicht leichter werden. Angebote wie das von{" "}
                    <Link to={urls.survey} target="_blank" rel="noreferrer noopener">
                        miracl
                    </Link>{" "}
                    sind darum genau auf die Bedürfnisse von normal vermögenden Menschen zugeschnitten.
                </p>
                <hr />

                <h2>Was gibt es Neues auf dem Finanzierungssektor?</h2>
                <p>
                    Die Normalverbraucher:in ist beim Hauskauf daher mehr denn je auf niedrige{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    angewiesen. Doch auch damit sieht es im Moment schlecht aus. Befanden sich die Zinsen für
                    Immobilienfinanzierungen seit jahrzehnten auf einem historischen Tief, so ist der Preis für
                    Fixzinsbindungen von zehn Jahren seit Beginn des Jahres um etwa zwei Prozent gestiegen. Diese
                    Teuerung betrifft auch variable Zinsmodelle, die seit Anfang 2022 auf etwa 0,6 % gestiegen sind.
                    Schuld an dieser Entwicklung ist eine wegen Coronapandemie und Ukrainekrieg historisch hohe
                    Inflationsrate, die die EZB Ende Juli 2022 zur Erhöhung ihrer Leitzinsen um 0,5 % bewegt hat.
                    Vergleicht man diese Maßnahmen mit jenen, die die us-amerikanische FED zur Inflationsbekämpufng
                    einsetzt, so lässt sich folgern, dass es auch in Europa zu einem anhaltender Anstieg der
                    Kreditzinsen kommen könnte.
                </p>
                <hr />

                <h2>Neue Kreditregeln seit Juli 2022</h2>
                <p>
                    Auf dem Finanzierungssektor ist eine weitere Sache neu: Seit Juli 2022 gelten auch in Österreich
                    veränderte Kreditregeln. Die neuen Kreditregeln sehen vor, dass Finanzierungen zum Kauf von
                    Immobilien ausschließlich dann zu vergeben sind, wenn die potentielle Kreditnehmer:in Eigenkapital
                    in der Höhe von 20 % des Kaufpreises besitzt – inkl. der{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>
                    , versteht sich. Was bei vielen Banken bereits Usus und auch eine Empfehlung der OeNB war, wir damit
                    zur gesetzlichen Pflicht. Hinzu kommt, dass die monatliche Kreditrate mit Inkrafttreten der neuen
                    Regularien höchstens 40 % deines Nettoeinkommen betragen darf. Auch das soll breite Zahlungsausfälle
                    verhindern. Die letzte Änderung betrifft die Laufzeit von Immobilienkrediten: Diese darf höchstens
                    35 Jahre betragen. Wie sich die Länge der Laufzeit auf deine monatliche Rate auswirkt,
                    veranschaulicht unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"unsichereZeitenAuswirkungenAufDenHauskauf"}></BreadcrumbList>
            <ArticleStructuredData
                page={"unsichereZeitenAuswirkungenAufDenHauskauf"}
                heading={"Unsichere Zeiten: Auswirkungen auf den Hauskauf"}
            />
        </Layout>
    );
};

export default UnsichereZeitenAuswirkungenAufDenHauskauf;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "header"
                        "footer"
                        "page.artikel.unsichere-zeiten-auswirkungen-auf-den-hauskauf"
                        "mortgageCalc"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
